import React from 'react'
import ReportBox from '../Components/ReportBox'

const Reports = () => {
  return (
    <>
      <ReportBox/>
    </>
  )
}

export default Reports
