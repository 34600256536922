import React from 'react'
import PersistentDrawerLeft from './PersistentDrawerLeft'

const DashboardHome = () => {
    return (
        <>
            <PersistentDrawerLeft />
        </>
    )
}

export default DashboardHome
